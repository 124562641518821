@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Gruppo&family=Quicksand&family=Roboto+Flex:wght@300&display=swap");

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &_about {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
}
.about_text,
.about_image {
  width: 60%;
  height: 50%;
  align-self: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: black;
}

.about_heading {
  font-weight: 600;
  font-family: "Gruppo", cursive;
  font-size: 20px;
  margin-bottom: 10px;
}

.pictures_container {
 margin-top:10%
}

.pictures_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.pictures_container_image {
    width: 250px;
    height: 125px;
    margin: 20px;
}

.pictures_container_image_sign {
    width: 200px;
    height: 80px;
}