@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Gruppo&family=Roboto+Flex:wght@300&display=swap");

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; 
  background-color: #a7c2d3;
  height: 100px;
  position: relative;

  &_logo {
    width: 150px;
    height: auto; 
  }

  &_regular {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &_element {
    margin: 0 10px;
    padding: 10px;
    list-style: none;
    align-items: center;
    color: #902633;
    font-family: "Gruppo", sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      transition: 1s ease-in;
    }
  }
}

.refference {
  text-decoration: none;
  color: black;
  font-size: 20px;
  padding: 20px;
  text-transform: uppercase;
  font-family: "Gruppo", cursive;
  font-weight: 600;
  color: #902633;
  text-align: center;
}

.hamburger {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 950px) {
  .menu_regular {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 100px; 
    left: 0;
    z-index: 10;

    .menu_element {
      margin: 10px 0;
      text-align: center;
    }
  }

  .menu_open {
    display: flex;
  }

  .hamburger {
    display: block;
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 20;
  }

  .menu {
    justify-content: space-between;
  }
}