.phoneContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: -100px; 
  min-width: 80%;
}

.video {
  margin-top: 50px;
  text-align: center;
}
video{
      width: 80vw;
}

.phoneImg {
  display: flex;
  justify-content: center;
}

.btnSlider {
  font-size: 30px;
  font-weight: 800;
  background-color: transparent;
  border: none;
  align-self: center;
  margin: 10vh 3vw;
  &:hover {
    cursor: pointer;
  }
}

.phoneImgBack {
  margin-top: 50px;
  height: 74vh;
  margin-left: -33px;
  position: absolute;
}
