.contact_form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 50px;
}

.contact_form_btn {
    border: none;
    background-color: #a7c2d3;
    color: black;
    font-size: 20px;
    height: 50px;
}

.contact_form_text {
    font-size: 20px;
}

.contact_heading {
    margin-top: 60px;
    text-align: center;
}

input,
textarea {
    width: 500px;
    margin: 20px;
    border: 1px solid black;
}

input {
    height: 40px;
}

textarea {
    height: 200px;
}

.contact_and_map {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
}

.map_info {
    margin-top: 86px;
}

.map {
    width: 38vw;
    height: 35vh;
}


@media screen and (max-width: 1250px) {
    .contact_and_map {
        flex-direction: column; 
    }
    .map_info{
        margin: auto;
    }
}