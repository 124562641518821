body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  overflow-y: auto;
}

section {
  max-width: 80%;
  margin: auto;
  margin-top: 100px;
}

.section_heading {
  font-family: "Gruppo", cursive;
  font-weight: 600;
  color: #902633;
  text-transform: uppercase;
  margin: 40px;
  font-size: 32px;
}

