
.footer {
//   position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff; 
  box-sizing: border-box;
}

.footer_regular {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  list-style: none;
  text-decoration: none;
  align-items: center;
}

.footer_element {
  text-decoration: none;
  color: black;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Gruppo", cursive;
  font-weight: 600;
  color: #902633;
  text-align: center;
}

.footer_element:hover {
  color: black;
}

@media (max-width: 950px) {
  .hidden_on_small {
   display: none;
  }
}