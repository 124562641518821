.slogans {
    margin-top: 0;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr 2fr 2fr;
}

.slogan {
    margin-top: 50px;
}

.slogan_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -20px;
    align-self: flex-start;
}

.slogan_element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;
}

.slogan_icon {
    width: 70px;
    margin: auto;
    margin-bottom: 20px;
}

.slogan_inner {
    list-style: none;
    margin: auto;
    width: 250px;
    display: flex;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 18px;
}

@media (max-width: 950px) {
    .slogans {
      grid-template-columns: 1fr 1fr; /* Change to 2-column grid for smaller screens */
      grid-template-rows: auto; /* Adjust row height to auto */
      gap: 20px; /* Optional: Add some gap between grid items */
    }
  }

  @media (max-width: 650px) {
    .slogans {
      grid-template-columns: 1fr; /* Change to 1-column grid for smaller screens */
      grid-template-rows: auto; /* Adjust row height to auto */
      gap: 20px; /* Optional: Add some gap between grid items */
    }
  }