.technology_bold {
    font-weight: 600;
    font-family: "Gruppo", cursive;
    font-size: 20px;
    margin-bottom: 10px;
}

.technology_advantages {
    width: 90%;
    text-align: center;
}

.technology_advantages_list_image {
    width: 50px;
}

li {
    list-style: none;
}

.technoligy_element {
    width: 30%;
    text-align: center;
}

.technoligy_element_text {
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: black;
    text-align: center;
}

.technology_element_text_additional {
    margin-top: 50px;
}

.technoligy_element_image {
    /* width: 350px; */
    width: 20vw;
    margin-bottom: 50px;
}

.technology_side {
    margin: auto;
    text-align: center;
    justify-content: center;
}

.technology_side_image {
    margin: 30px;
    width: 25vw;
    height: 300px;
    justify-content: center;
}