.container_blog {
    display: flex;
    flex-direction: column;
}

.blog_element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}

.blog_element_article_image {
    height: 400px;
    border-radius: 14% 36%;
    margin-right: 50px;
}

.blog_element_text_intro {
    width: 70%;
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    text-align: justify;
}

.blog_element_intro_center {
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
    /* font-weight: 500; */
    font-weight: bolder;
    text-align: center;
}

h3 {
    font-family: "Gruppo", cursive;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
}

.blog_element_image {
    width: 500px;
    height: 300px;
}

.art1Img {
    display: flex;
    justify-content: center;
}

.article1_image {
    margin: 100px;
    width: 500px;
    height: 300px;
}

.blog_element_text {
    margin: auto;
    font-family: "Gruppo", cursive;
    font-size: 20px;
    font-weight: 500;
}

.blog_element_article {
    margin-top: 100px;
}


/* .toggle-box {
    display: none;
}

.toggle-box+label {
    display: block;
}

.toggle-box+label+div {
    display: none;
}

.toggle-box:checked+label+div {
    display: block;
    padding: 5px;
} */

.main-container p {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
}

.blog_element_article_main {
    display: flex;
    flex-direction: column;
}

.left {
    width: 50%;
}

.container-fluid,
.main-container {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.articleImage {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.img1 {
    width: 65%;
    margin:20px auto; text-align: center;
}

.img2 {
    width: 50%;
    margin:20px auto; text-align: center;
}

.img3 {
    width: 40%;
    margin:20px auto; text-align: center;
}

.img4,
.img5 {
    width: 60%;
    margin:20px auto; text-align: center;
}

.img6 {
    width: 45%;
    margin:20px auto; text-align: center;
}

.img7 {
    width: 70%;
    margin:20px auto; text-align: center;
}

.img8 {
    width: 55%;
    margin:20px auto; text-align: center;
}

.figure{
    text-align: center; margin:30px;
}

.formula{
    font-weight: bold;
}

.center{
    display: flex;
    justify-content: center;
}

.bold{
    font-weight: 600;
}

@media all and (max-width: 850px) {
    .blog_element_article_image {
        height: 200px;
    }
    .blog_element_intro,
    .blog_element_intro_center {
        font-size: 15px;
    }
}

@media all and (max-width: 850px) {
    .blog_element_article_image {
        width: 150px;
        margin-bottom: 50px;
    }
    .blog_element {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

